import { GlobalData } from 'src/app/providers/GlobalData';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, TimeoutError, Subject } from 'rxjs';
import { Logger } from './Logger';
import { timeout } from 'rxjs/operators';
import { NativeService } from './NativeService';
import { Helper } from './Helper';
import { Utils } from './Utils';
import { HttpHelper, RequestSetting } from './HttpHelper';
import { Encrypt } from './Encrypt';
import { StorageManage } from './StorageManage';
import * as pako from "pako";
/**
 * 封装angular http
 */
@Injectable({
    providedIn: 'root'
})
export class HttpService extends HttpHelper {
    public imEvent = new Subject<any>();
    public imEventHis = new Subject<any>();
    public callRingEvent = new Subject<any>();

    constructor(
        public utils: Utils,
        public http: HttpClient,
        public helper: Helper,
        public native: NativeService,
        public storage: StorageManage,
        public nav: NavController,
    ) {
        super(helper);
    }
    getMessage(): Observable<any> {
        return this.imEvent.asObservable();
    }
    getHisMessage(): Observable<any> {
        return this.imEventHis.asObservable();
    }
    getCallRing(): Observable<any> {
        return this.callRingEvent.asObservable()
    }
    public sendCallRing(message) {
        this.callRingEvent.next(message);
    }


    //========开发阶段不同项目会连接不同的后端服务，下面各系统指向哪里在这里设置
    toservicehost: any = this.getToserviceHost()

    toInteligentServiceGet(page: string, param: any): Observable<any> {
        return Observable.create(observer => {
            this.checkTokenOverTime().subscribe(
                (result) => {
                if (result) {
                    var url = "service/ToServiceKF.ashx?api=" + page;
                    param["toservice"] = "intelligentcenterservice"

                    var setting: RequestSetting = { to: "app", method: "GET", contentType: "json" }
                    // param.action = action
                    // param.source = 1
                    this.get(url, param, setting).subscribe((res) => {
                        observer.next(res)
                    })
                }
                else
                    observer.error(false)
                },
                (error) => {
                    observer.error(false)
                }
            )
        })
    }

    //应用服务(API POST)
    public toInteligentServicePost(page: string, param: any = {}): Observable<any> {
        return Observable.create(observer => {
            this.checkTokenOverTime(page).subscribe(
                (result) => {
                if (result) {
                    var url = "service/ToServiceKF.ashx?api=" + page + "&toservice=intelligentcenterservice";
                    var setting: RequestSetting = { to: "app", method: "POST", contentType: "json" }
                    if (page.indexOf("getChatsByVisits") != -1 || page.indexOf("getChatsByConversation") != -1 || page.indexOf("getRealtimeMsgFileByConfrId") != -1) {
                        url = "service/ToServiceKF.ashx?api=" + page + "&toservice=imservice";
                        setting = { to: "app", method: "POST", contentType: "form" }
                    } else if (page.indexOf("knowledgebasere") != -1) {//中枢知识库
                        url = "service/ToServiceKF.ashx?api=" + page + "&toservice=operateadmin";
                        setting = { to: "app", method: "POST", contentType: "json" }
                    } else if (page.indexOf("restoreOSSObject") != -1) {
                        url = "service/ToServiceKF.ashx?api=" + page + "&toservice=imservice";
                        setting = { to: "app", method: "POST", contentType: "json" }
                    }
                    this.post(url, param, setting).subscribe((res) => {
                        observer.next(res)
                    })
                }
                else
                    observer.error(false)
                },
                (error) => {
                    observer.error(false)
                }
            )
        })
    }
    public toIHospitalServicePost(action: string, param: any, sendtype: string = "json") {
        return Observable.create(observer => {
            this.checkTokenOverTime().subscribe((result) => {
               if(result){
                var url = "service/ToServiceKF.ashx?api=" + action + "&toservice=internethospital";
                var setting: RequestSetting = { to: "app", method: "POST", contentType: "json" }
                this.post(url, param, setting).subscribe((res) => {
                    observer.next(res)
                })
               }else
               observer.error(false)
            })
        })
    }

    toCenterServicePost(action: string, param: any, sendtype: string = "json"): Observable<any> {
        action += "&toservice=" + this.toservicehost.center + "center"
        return Observable.create(observer => {
            this.checkTokenOverTime().subscribe((result) => {
               if(result){
                var url = "service/ToServiceKF.ashx?api=" + action;
                var setting: RequestSetting = { to: "app", method: "POST", contentType: "json" }
                this.post(url, param, setting).subscribe((res) => {
                    observer.next(res)
                })
               }else
               observer.error(false)
            })
        })
    }

    toCenterServiceGet(page: string, param: any): Observable<any> {
        return Observable.create(observer => {
            this.checkTokenOverTime().subscribe((result) => {
                if (result) {
                    var url = "service/ToServiceKF.ashx?api=" + page;
                    param["toservice"] = "center"

                    var setting: RequestSetting = { to: "app", method: "GET", contentType: "json" }
                    // param.action = action
                    // param.source = 1
                    this.get(url, param, setting).subscribe((res) => {
                        observer.next(res)
                    })
                }
                else
                    observer.error(false)
            })
        })
    }
    
    //应用服务（QYSPost）
    public toServicePost(page: string, param: any): Observable<any> {
        return Observable.create(observer => {
            this.checkTokenOverTime().subscribe((result) => {
                if (result) {
                    var url = "service/ToServiceKF.ashx?api=" + page + "&toservice=" + this.toservicehost.qys + "docservice"
                    var setting: RequestSetting = { to: "app", method: "POST", contentType: "json" }
                    // param.action = action;
                    // param.source = 1;
                    this.post(url, param, setting).subscribe((res) => {
                        observer.next(res)
                    })
                }
                else
                    observer.error(false)
            })
        })
    }

    //应用服务（QYSGet）
    public toServiceGet(page: string, param: any): Observable<any> {
        return Observable.create(observer => {
            this.checkTokenOverTime().subscribe((result) => {
                if (result) {
                    var url = "service/ToServiceKF.ashx?api=" + page + "&toservice=docservice"
                    var setting: RequestSetting = { to: "app", method: "GET", contentType: "json" }
                    // param.action = action
                    // param.source = 1
                    this.get(url, param, setting).subscribe((res) => {
                        observer.next(res)
                    })
                }
                else
                    observer.error(false)
            })
        })
    }
    //应用服务(API POST)
    public toServicePostForApi(action: string, param: any = {}): Observable<any> {
        return Observable.create(observer => {
            var setting: RequestSetting = { to: "app", method: "POST", contentType: "json" }
            this.post(action, param, setting).subscribe((res) => {
                observer.next(res)
            })
        })
    }
    
    //应用服务(API GET)
    public toServiceGetForApi(action: string, param: any = {}): Observable<any> {
        return Observable.create(observer => {
            var setting: RequestSetting = { to: "app", method: "GET", contentType: "json" }
            this.get(action, param, setting).subscribe((res) => {
                observer.next(res)
            })
        })
    }
    //授权服务（AuthPost）
    public toAuthServicePost(page: string, param: any) {
        var setting: RequestSetting = { to: "auth", contentType: "json" }
        return this.post(page, param, setting)
    }
    //授权服务（AuthGet）
    public toAuthServiceGet(page: string, param: any) {
        var setting: RequestSetting = { to: "auth" }
        return this.get(page, param, setting)
    }
    //应用服务请求API
    toAppServiceApiPost(action: string, param: any, sendtype: string = "form") {
        var setting: RequestSetting = { to: "app", method: "POST", contentType: sendtype }
        return this.post(this.getHost() + action, param, setting);
    }
    toServiceApiPost(action: string, param: any, sendtype: string = "form") {
        var setting: RequestSetting = { to: "app", method: "POST", contentType: sendtype }
        return this.post(action, param, setting);
    }

    //Get方式发送(不需要有登录验证的请求)
    toServiceNoPowerGet(action: string, param: any) {
        param["toservice"] = "newapp/service";
        var setting: RequestSetting = { to: "app", method: "GET", contentType: "form" }
        return this.get('service/ToServiceKFNopower.ashx?api=' + action, param, setting);
    }

    //Post方式发送(不需要有登录验证的请求)
    toServiceNoPowerPost(action: string, param: any, sendtype: string = "form") {
        action += "&toservice=newapp/service";
        var setting: RequestSetting = { to: "app", method: "POST", contentType: sendtype }

        return this.post('service/ToServiceKFNopower.ashx?api=' + action, param, setting);
    }

    toIHospitalServiceNoPowerPost(action: string, param: any, sendtype: string = "json") {
        action += "&toservice=" + "internethospital"
        var setting: RequestSetting = { to: "app", method: "POST", contentType: sendtype }
        return this.post('service/ToServiceKFNopower.ashx?api=' + action, param, setting);
    }

    toServiceNewAppPost(page: string, param: any, sendtype: string = "json"): Observable<any> {
        return Observable.create(observer => {
            this.checkTokenOverTime().subscribe((result) => {
                if (result) {
                    var url = "service/ToServiceKF.ashx?api=" + page + "&toservice=newapp/service"
                    var setting: RequestSetting = { to: "app", method: "POST", contentType: sendtype }
                    // param.action = action;
                    // param.source = 1;
                    this.post(url, param, setting).subscribe((res) => {
                        observer.next(res)
                    })
                }
                else
                    observer.error(false)
            })
        })
    }

    //知识库Post请求
    public toKBaseServicePost(page: string, param: any = {}): Observable<any> {
        return Observable.create(observer => {
            this.checkTokenOverTime().subscribe((result) => {
                if (result) {
                    var url = "operateadmin/cental/knowledgebasere/" + page;
                    var setting: RequestSetting = { to: "kBase", method: "POST", contentType: "form" }
                    this.post(url, param, setting).subscribe((res) => {
                        observer.next(res)
                    })
                }
                else
                    observer.error(false)
            })
        })
    }


    //检测Token是否过期
    checkTokenOverTime(page = ''): Observable<any> {
        return Observable.create(async observer => {
            if (GlobalData.userInfo == null || GlobalData.userInfo.CID == null )//cid为空说明没有登录
            {
                this.helper.hideLoading();
                console.log(page);
                this.nav.navigateForward("centresyslogin", { queryParams: { isRedirect: true, isAllowEditPhone: true, errormsg: "您还没有登录" } });
                observer.error(false);
                // 跳转登录页面
            }
            else if (parseInt(GlobalData.userInfo.OverTime) <= new Date().getTime()) {//如果Token超时
                var url = "authapi/Auth/RefreshToken?"
                this.toAuthServiceGet(url, {
                    refreshToken: GlobalData.userInfo.refresh_token,
                    cid: GlobalData.userInfo.CID
                }).subscribe((res) => {
                    if (res.errorcode == "00000000") {//申请新Token成功
                        this.utils.saveTokenToLocal(res.data).subscribe(result => {
                            observer.next(true)
                        }, err => {
                            observer.error(false)//出错
                        })
                    } else if (res.errorcode == "00000006"||res.errorcode == "88880626"||res.errorcode == "80000026") {//刷新码过期，重新登陆
                        if(sessionStorage.getItem('currentPID')){
                            this.nav.navigateForward("centresyslogin", { queryParams: { isRedirect: true, isAllowEditPhone: true, errormsg: "您还没有登录",
                            currentPID:sessionStorage.getItem('currentPID'),currentCID:sessionStorage.getItem('currentCID'),currentBussNo:sessionStorage.getItem('currentBussNo')} });
                        }else{
                            this.nav.navigateForward("centresyslogin", { queryParams: { isRedirect: true, isAllowEditPhone: true, errormsg: "您还没有登录" } });
                        }
                    }
                    else observer.error(false)//失败
                })
            }
            else
                observer.next(true)
        });
    }
    private get(url: string, params: any = {}, setting: RequestSetting = {}) {
        setting.method = "GET"
        const options = {
            method: setting.method,
            url: url + "&" + Encrypt.buildAutograph(params),
        };
        return this.doRequest(options, setting);
    }

    private post(url: string, body: any = {}, setting: RequestSetting = { contentType: "" }): Observable<any> {
        var httpHeaders: HttpHeaders
        setting.method = "POST"
        switch (setting.contentType.toLocaleLowerCase()) {
            case "json":
                httpHeaders = new HttpHeaders({
                    'Content-Type': 'application/json; charset=UTF-8'
                })
                body = Encrypt.buildAutograph(body, "json")
                break;
            case "form":
                httpHeaders = new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                })
                body = Encrypt.buildAutograph(body)
                break;
            default: httpHeaders = new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            })
                break;
        }
        const options = {
            method: setting.method,
            url: url,
            body,
            headers: httpHeaders
        };
        return this.doRequest(options, setting);
    }


    private jsonToFrom(body) {
        var result = ""
        for (let key in body) {
            if (body[key] == undefined || body[key] == null)
                body[key] = "";
            result += key + '=' + body[key] + '&';
        }
        return result
    }



    public delete(url: string, params: any = {}, setting: RequestSetting = {}) {
        const options = {
            method: 'DELETE',
            url: url,
            params
        };
        return this.doRequest(options, setting);
    }

    public postFormData(url: string, params: any = {}, setting: RequestSetting = {}): Observable<any> {
        const options = {
            method: 'POST',
            url: url,
            params,
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            })
        };
        return this.doRequest(options, setting);
    }

    public doRequest(options, setting: RequestSetting) {
        const defaultSetting = HttpHelper.getDefaultSetting(setting);
        return defaultSetting.useDefaultApi ? this.defaultRequest(options, defaultSetting) : this.request(options, defaultSetting);
    }

    /**
     * 一个app可能有多个后台接口服务(api),针对主api添加业务处理
     */
    public defaultRequest(options, setting: RequestSetting): Observable<any> {
        //  使用默认API:APP_SERVE_URL
        switch (setting.to) {
            case "auth":
                options.url = environment.authServerUrl + options.url;
                break;
            case "app":
                if (options.url.indexOf("restoreOSSObject") != -1) {
                    options.url = environment.ossServerUrl + options.url;
                } else
                    if (options.url.indexOf("getChatsByVisits") != -1 || options.url.indexOf("getRealtimeMsgFileByConfrId") != -1) {
                        options.url = environment.chatsServerUrl + options.url;
                    }
                    else {
                        options.url = environment.appServerUrl + options.url;
                    }
                break;
            // case "kBase":
            //     options.url = environment.kBaseServerUrl + options.url;
            //     break;
            default: {
                    options.url = environment.appServerUrl + options.url;
            }
                break;
        }
        //  添加请求头
        // var userInfo= this.storage.getAppStorage('userInfo');
        // // this.storage.getAppStorage('userInfo').then(userInfo=>{
        // console.log(userInfo);
        //     GlobalData.userInfo=userInfo;
        // console.log(GlobalData.userInfo)

        if (GlobalData.userInfo && GlobalData.userInfo.access_token) {
            options.headers = options.headers || new HttpHeaders();
            options.headers = options.headers.set('Authorization', `Bearer ${GlobalData.userInfo.access_token}`);  // 注：set append返回新的HttpHeaders
        }


        // })
        return Observable.create(observer => {
            this.request(options, setting).subscribe(res => {
                observer.next(res); // data是主api约定返回的数据
            }, err => {
                observer.error(err);
            });
        });

    }

    public request(ops, set: RequestSetting): Observable<any> {
        const options = {
            url: '',
            method: set.method,
            body: null,
            params: null,
            urlWithParams: false,
            headers: null,
            reportProgress: false,
            withCredentials: false,
            responseType: 'json',//返回数据格式
            ...ops
        };
        const setting = HttpHelper.getDefaultSetting(set);
        options.url = Utils.formatUrl(options.url);
        return Observable.create(observer => {
            // 如果需要缓存，先尝试从sessionStorage中取数据
            if (setting.needCache) {
                const cacheResult = HttpHelper.getCacheData(options);
                if (cacheResult) {
                    observer.next(cacheResult);
                    return;
                }
            }
            this.requestBefore(options, setting);
            // console.log(options);
            this.http.request(options.method, options.url, options).pipe(
                timeout(environment.requestTimeout)
            ).subscribe(res => {
                setting.needCache && HttpHelper.setCacheData(options, res); // 如果需要缓存，保存数据到sessionStorage中
                let d = JSON.parse(JSON.stringify(res));
                if (d.A) {
                    try {
                        const key = Encrypt.toGm();
                        let data = Encrypt.getDAesString(d.D, key, key);
                        data = pako.inflate(Encrypt.base64ToUint8Array(data), { to: 'string' });
                        d = JSON.parse(data);
                        observer.next(d);
                    } catch (e) {
                        console.log(e);

                    }
                }else  observer.next(res);
                this.requestSuccess(options);
            }, err => {
                this.requestError(options);
                observer.error(this.requestFailedHandle(options.url, err));
            });
        });
    }

    /**
     * 处理请求失败事件
     */
    private requestFailedHandle(url: string, err: HttpErrorResponse) { // : Response
        const status = err.status;
        let msg = '请求发生异常，请联系管理员';
        // 与后台约定，状态码为400即为业务异常
        if (status === 0) {
            msg = '可能后台服务未启用';
        } else if (status === 404) {
            msg = status + ' 未找到请求地址';
        } else if (status === 500) {
            msg = status + ' 服务器出错，请稍后再试';
        }
        else if (status === 401)
            msg = status + ' 身份验证已过期,请重新登录';//  this.helper.toast('密码已过期,请重新登录');
        else {
            msg = status + err.message
            // this.helper.alert('提示', errData.message || msg);
        }

        if (this.helper.isMobile() && !this.native.isConnecting()) {
            this.helper.alert('请连接网络');
        } else if (err instanceof TimeoutError) {
            // this.helper.alert('提示', '请求超时,请稍后再试!');
        } else {
            // this.helper.alert('请求失败', msg);
            Logger.http(err, {
                url,
                status
            });
        }
        return err;
    }
    //开发指向地址
    getToserviceHost() {
        if (this.getHost() == "https://qyi.careate.cn//")//正式版打包返回空   此判断就是怕误将测试配置发版到正式
            return {
                "qys": "",
                "center": "",
                "health": "",
                "article": "",
                "articlejava": ""
            };
        else
            return {
                "qys": "",
                "center": "",
                "health": "",
                "article": "",
                "articlejava": ""
            };

    }
    //主机地址
    getHost() {
        var host = "";
        var url = this.storage.getStorage("hostUrl");
        // console.log(url)
        if (url == "undefined" || url == null || url == "") {
            host = environment.appServerUrl;
            if (host != "")
                this.storage.setStorage("hostUrl", host);
            else
                this.storage.setStorage("hostUrl", environment.appServerUrl);//再试一次
        }

        return this.storage.getStorage("hostUrl") + "/";

    }
    //校对本地时间
    checkLocalTime() {
        var host = this.getHost();
        // console.log(host)
        return this.http
            .get(host + "api/Account/GetTime")
            .pipe(
                timeout(environment.requestTimeout)
            )
            .subscribe(res => {
                var localTime2 = new Date();//当前本地时间
                var serverTime = res['data']//服务器时间
                // console.log(serverTime)
                var date4 = localTime2.getTime() - serverTime

                //alert("与服务器的时间差：" + localTime2.getTime()+"||"+serverTime)
                if (date4 < -5000 && date4 > -1853538) //本地时间慢需要补充 1853538
                {
                    this.storage.setStorage("correcttime", date4.toString().replace("-", ""))
                }
                else if (date4 > 5000 && date4 < 1853538)//本地时间快需要减慢 -1853538
                {
                    this.storage.setStorage("correcttime", "-" + date4)
                }
                else {
                    this.storage.setStorage("correcttime", "0")
                }
                return
            });

    }
    //检测图片是否存在 img标签下name=photo的 默认医生头像 1：医院logo
    checkImgExist(type?: number) {
        setTimeout(() => {
            var item = $("img[name='photo']")
            for (var i = 0; i < item.length; i++) {
                if ($(item[i]).css("width") == "20px") {
                    if (type == 1) {//医院
                        $(item[i]).attr("src", this.getHost() + "/Photo/doctorimg/doctor_07.png")
                    }
                    else {
                        $(item[i]).attr("src", this.getHost() + "/Photo/doctorimg/doctor_07.png")
                    }
                }
            }
        }, 1000);
    }
}
